import React, { Fragment, useContext } from 'react'
import { GlobalContext } from '../../data/GlobalContext'
import { Container } from 'react-bootstrap'
import HomeBlockTwo from './HomeBlockTwo'
import HomeBlock from './HomeBlock'
import Banner from './Banner'
import CounterBlack from '../../layouts/ConuterBlack'
import ServicesBlock from './ServicesBlock'
import SocialMediaBlock from './SocialMediaBlock'
import HeroImage from '../../layouts/HeroImages'
import AboutContent from '../../sections/About/AboutContent'
import FormContent from '../contact/FormContent'
import VideoPromocional from './VideoPromocional'



const Content = () => {
    const dbData = useContext(GlobalContext)
    return (
      <Fragment>
        <Banner />
        <HomeBlock />
        {dbData.dbSocialMedia.redes[0].name !== " " ? (
          <SocialMediaBlock />
        ) : (
          <div className="spacerDiv"></div>
        )}
        <VideoPromocional
          linkVideo={`${dbData?.videosPromo?.[0].link}`}
          image={`${dbData?.videosPromo?.[0].img}`}
          vimeoVideo
        />

        <HomeBlockTwo />
        <CounterBlack />
        <HeroImage bgImage={dbData.stock[0]} />
        <Container>
          <AboutContent />
        </Container>
        <ServicesBlock />
        <Container>
          {/* reviews */}
          {/* <div className='py-5'>
                    <h1 className='pb-5 text-capitalize text-center'>Our Reviews</h1>
                    <div class="elfsight-app-65303283-1fc1-4664-8f25-1c5904fb7a2d"></div>
                </div> */}
          {/* Contact form */}
          <div className="contact-form grey-bg">
            <div className="row no-gutters justify-content-center">
              <div className="col-10">
                <div className="section-title text-center mb-40">
                  <h2 className="title pt-4">Don’t Hesited To Contact Us</h2>
                </div>
                <FormContent />
              </div>
            </div>
          </div>
        </Container>
        <div className="mt-50 ">
          {dbData.dbPrincipal.miles !== " " ? (
            <h1 className="pt-5 text-center text-capitalize">
              we cover {dbData.dbPrincipal.miles} miles around{" "}
              {dbData.dbPrincipal.location[0].address}
            </h1>
          ) : (
            <h1 className="pt-5 text-center text-capitalize">
              we cover the entire state of{" "}
              {dbData.dbPrincipal.location[0].address}
            </h1>
          )}
          <iframe
            className="mapContent"
            title="map"
            src={dbData.dbPrincipal.location[0].url}
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex={0}
          />
        </div>
      </Fragment>
    );
}


export default Content;