import React, { useContext } from 'react'
import { GlobalContext } from '../../data/GlobalContext';
import { Row, Col } from 'react-bootstrap'
import line1 from '../../../assets/img/lines/12.png'
import line2 from '../../../assets/img/lines/11.png'


// const imgUrl = require.context('../../../assets/img', true);

function SocialMediaBlock(props) {
    const dbData = useContext(GlobalContext);
    return (
        <section className="service-section shape-style-two service-line-shape section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                {
                    dbData.gmb.link !==' ' ?
                    <div className='service-boxes d-flex justify-content-center'>
                        <a href={dbData.gmb.link} target="_blank" rel='noopener noreferrer'>
                            <img src={dbData.gmb.img} className='google' alt="Icon" />
                        </a>
                    </div>
                    : null

                }
                <div className="section-title text-center both-border mb-50">
                    <h2 className="title text-capitalize">follow us</h2>
                </div>
                {/* Services Boxes */}
                <Row className="service-boxes justify-content-center">
                    {
                        dbData.dbSocialMedia.directorios[0].name !== ' ' ?
                            dbData.dbSocialMedia.directorios.map((item, i) => (
                                <Col key={i} sm={12} md={4} lg={3} className="wow fadeInUp d-flex align-items-center">
                                    <a href={item.url} target="_blank" rel='noopener noreferrer' className='py-3'>
                                        <img src={item.image} alt="Icon" />
                                    </a>
                                </Col>
                            ))
                            : null
                    }
                    {
                        dbData.dbSocialMedia.redes[0].name !== ' ' ?
                            dbData.dbSocialMedia.redes.map((item, i) => (
                                <Col key={i} sm={12} md={4} lg={3} className="wow fadeInUp d-flex align-items-center">
                                    <a href={item.url} target="_blank" rel='noopener noreferrer' className='py-3'>
                                        <img src={item.image} alt="Icon" />
                                    </a>
                                </Col>
                            ))
                            : null
                    }
                </Row>
            </div>
            <div className="line-one">
                <img src={line1} alt="line-shape" />
            </div>
            <div className="line-two">
                <img src={line2} alt="line-shape" />
            </div>
        </section>
    );
}

export default SocialMediaBlock;