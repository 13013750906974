import React, {useContext} from 'react'
import { GlobalContext } from '../../data/GlobalContext';
import { Link } from 'react-router-dom'
// import Slider from 'react-slick'



// const SampleNextArrow = (props) => {
//     const { onClick } = props
//     return (
//         <button className="slick-arrow next-arrow" onClick={onClick}><i className="fal fa-long-arrow-right" /></button>
//         )
//     }

    // const SamplePrevArrow = (props) => {
    //     const { onClick } = props
    //     return (
    //         <button className="slick-arrow prev-arrow" onClick={onClick}><i className="fal fa-long-arrow-left" /></button>
    //         )
    //     }
        function Banner(props) {
            const dbData = useContext(GlobalContext);

            // const bannerslide = [
            //     {
            //         img: `${dbData.stock[8]}`,
            //         title: `${dbData.dbSlogan[0].slogan}`,
            //         text: `${dbData.dbValues[0].description}`,
            //         btntext: 'Free Estimate',
            //         btntext1: 'Our Services'
            //     },
            //     {
            //         img: `${dbData.stock[9]}`,
            //         title: `${dbData.dbSlogan[1].slogan}`,
            //         text: `${dbData.dbValues[1].description}`,
            //         btntext: 'Free Estimate',
            //         btntext1: 'Our Services'
            //     },
            //     {
            //         img: `${dbData.stock[6]}`,
            //         title: `${dbData.dbSlogan[3].slogan}`,
            //         text: `${dbData.dbValues[2].description}`,
            //         btntext: 'Free Estimate',
            //         btntext1: 'Our Services'
            //     },
            // ]
            // const settings = {
            //     infinite: true,
            //     slidesToShow: 1,
            //     slidesToScroll: 1,
            //     autoplay: true,
            //     autoplaySpeed: 5000,
            //     speed: 500,
            //     arrows: true,
            //     fade: false,
            //     dots: false,
            //     swipe: true,
            //     adaptiveHeight: true,
            //     nextArrow: <SampleNextArrow />,
            //     prevArrow: <SamplePrevArrow />,
            //     responsive: [{
            //         breakpoint: 768,
            //         settings: {
            //             arrows: false
            //         }
            //     }],
            // }

            return (
              <section className="banner-section banner-section-two">
                {/* <Slider
                  className="banner-slider"
                  id="bannerSlider"
                  {...settings}
                >
                  {bannerslide.map((item, i) => (
                    <div key={i}>
                      <div
                        className="single-banner"
                        style={{ backgroundImage: `url('${item.img}')` }}
                      >
                        <div className="container">
                          <div className="row justify-content-center">
                            <div className="col-lg-10">
                              <div className="banner-content text-center">
                                <h1
                                  data-animation="fadeInDown"
                                  data-delay="0.8s"
                                >
                                  {item.title}
                                </h1>
                                <p data-animation="fadeInUp" data-delay="1s">
                                  {item.text}{" "}
                                </p>
                                <ul className="btn-wrap">
                                  <li
                                    data-animation="fadeInLeft"
                                    data-delay="1.2s"
                                  >
                                    <Link
                                      to="/contact"
                                      className="main-btn main-btn-4"
                                    >
                                      {item.btntext}
                                    </Link>
                                  </li>
                                  <li
                                    data-animation="fadeInRight"
                                    data-delay="1.4s"
                                  >
                                    <Link
                                      to="/services"
                                      className="main-btn main-btn-2"
                                    >
                                      {item.btntext1}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider> */}
                <div>
            <div className="content_video">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={'https://firebasestorage.googleapis.com/v0/b/videospromo-24754.appspot.com/o/Salazars%20Residential%20Remodeling%20LLC%202.mp4?alt=media&token=56fb591d-5446-4702-a425-b4abbfe32a55'}
                        type="video/mp4"
                    />
                </video>
                <div className="video_description">
                    <div className="descriptionv">
                        <h1 className="text-[25px] md:text-[3em] lg:text-[4em]">{dbData.dbSlogan[0].slogan}</h1>
                        <p className="px-5 md:px-[20%] text-[15px] lg:text-[1em]">{dbData.dbValues[0].description}</p>
                        <Link
                                      to="/contact"
                                      className="main-btn main-btn-4"
                                    >
                                      Free Estimate
                                    </Link>
                    </div>
                </div>
            </div>
        </div>
              </section>
            );
}

export default Banner;